// Load Styles
import '../scss/main.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

// // Load Bootstrap init
// import { initBootstrap } from "./bootstrap.js";

// // Loading bootstrap with optional features
// initBootstrap({
//   tooltip: true,
//   popover: true,
//   toasts: true,
// });



$('.owl-carousel.partnerzy').owlCarousel({
  loop: true,
  margin: 16,
  nav: false,
  autoplay: true,
  autoplayTimeout: 5000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 4,
      nav: false,

    },
    600: {
      items: 6,
      nav: true,
    },
    1000: {
      items: 10
    },
    1400: {
      items: 10
    }
  }
});
$('.owl-carousel.partnerzy-spa').owlCarousel({
  loop: true,
  margin: 32,
  nav: false,
  autoplay: true,
  autoplayTimeout: 5000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 4,
      nav: false,

    },
    600: {
      items: 6,
      nav: true,
    },
    1000: {
      items: 8
    },
    1400: {
      items: 8
    }
  }
});

$('.owl-carousel.polecane').owlCarousel({
  loop: true,
  margin: 16,
  nav: true,
  navText: ['<i class="fa-solid fa-chevron-left"></i>', '<i class="fa-solid fa-chevron-right"></i>'],
  autoplay: true,
  autoplayTimeout: 5000,
  autoplayHoverPause: true,
  center: true,
  responsive: {
    0: {
      items: 1,
      nav: false,

    },
    600: {
      items: 2,
      nav: true,
    },
    1000: {
      items: 4
    }
  }
});

$('.owl-carousel').owlCarousel({
  loop: true,
  margin: 16,
  nav: true,
  navText: ['<i class="fa-solid fa-chevron-left"></i>', '<i class="fa-solid fa-chevron-right"></i>'],
  responsive: {
    0: {
      items: 1,
      nav: false,

    },
    600: {
      items: 3,
      nav: true,
    },
    1000: {
      items: 5
    }
  }
});

const allRanges = document.querySelectorAll(".range-wrap");
allRanges.forEach(wrap => {
  const range = wrap.querySelector(".range");
  const bubble = wrap.querySelector(".bubble");

  range.addEventListener("input", () => {
    setBubble(range, bubble);
  });
  setBubble(range, bubble);
});

function setBubble(range, bubble) {

  const val = range.value;
  const min = range.min ? range.min : 0;
  const max = range.max ? range.max : 100;
  const newVal = Number(((val - min) * 100) / (max - min));
  const format = range.dataset.format ? range.dataset.format : "%s";
  bubble.innerHTML = format.replace("%s", val);

  // Sorta magic numbers based on size of the native UI thumb
  bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
}


function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

$(document).on('pjax:success', function (a, b) {

  if ($(a.target).length) {
    $('html, body').animate({ scrollTop: $(a.target).offset().top }, 1000);
    return;
  }


  if ($("#grid-oferty").length) {
    $('html, body').animate({ scrollTop: $('#grid-oferty').offset().top }, 1000);
  }
})



$("#formmodel-wszystkie").change(function () {
  $("#zgodyContainer input").prop("checked", $(this).prop("checked"));
});


$('p').each(function () {
  var tekst = $(this).html();
  tekst = tekst.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;"); //jednoznakowe
  tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, "$1$2&nbsp;"); //dwuznakowe
  $(this).html(tekst);
});

const rodoContainer = document.getElementById('rodoContainer')
if (rodoContainer) {
  rodoContainer.addEventListener('show.bs.collapse', event => {
    $(event.target).parent().addClass("opened-collapsible");
  })
  rodoContainer.addEventListener('hidden.bs.collapse', event => {
    $(event.target).parent().removeClass("opened-collapsible");
  })
}
const zgodyContainer = document.getElementById('zgodyContainer')
if (zgodyContainer) {
  zgodyContainer.addEventListener('show.bs.collapse', event => {
    $(event.target).parents("form").addClass("opened-collapsible2");
    $("[aria-controls='zgodyContainer']").html("&times;").addClass("btn btn-sm btn-secondary py-1 px-2 d-none")

  })

  zgodyContainer.addEventListener('hidden.bs.collapse', event => {
    $(event.target).parents("form").removeClass("opened-collapsible2");
    $("[aria-controls='zgodyContainer']").html(rozwin).removeClass("btn btn-sm btn-secondary py-1 px-2")
  })
}

$(document).on("submit", "#contact-form, .formularz-kontaktowy", function(e){
	
	setTimeout(function(){
		if(e.result == false){
			$("#zgodyContainer").collapse("show");
		} 
	},300);
})